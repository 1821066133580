::-webkit-scrollbar {
  display: none;
}

*{
  padding: 0px;
  margin: 0px;
  /* overflow-y: hidden; */
 
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  background-color: #282829;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* Hide the offcanvas on desktop */
@media screen and (max-width: 768px) {
  .serachInput {
    display: none;
    /* background-color: #1a1a1f; */

  }

}

@media screen and (max-width: 768px) {
  .logo1 {
    width: 70%;
    height:"100%";
  }
  .log{
    display: none !important;
  }
}

