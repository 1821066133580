* {
  padding: 0%;
  margin: 0%;
}


@media screen and (min-width: 768px) {
  .btnhidden {
    display: none;

  }
}

@media screen and (max-width: 767px) {
  .btnhidden {
    display: block;
    margin-top: -60px;
    margin-left: 320px;
    margin-bottom: 10px;
  }
}

.btnhidden {
  background-color: grey;

}


@media screen and (max-width: 768px) {
  .hideside {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .hideside {
    display: block;

  }
}


.side {
  color: white;
  display: flex;
  border-radius: 5px;
  padding: 5px;
  list-style: none;
  text-decoration: none;

}

.side:hover {
  color: white;
  display: flex;
  border-radius: 5px;
  padding: 5px;
  list-style: none;
  text-decoration: none;

}



.inactive {
  accent-color: #fff;
}

.active {
  background-color: rgb(241, 19, 123);
  display: flex;
  text-decoration: none;
  color: #fff !important;
}