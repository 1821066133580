*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}


  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(180deg, #FF69B4 50%, #fff 50%);
  }
  
  .login-box {
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    display: block;
    margin: 0 auto 20px;
    height: 60px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    font-size: 14px;
    color: #f12289;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #f12289;
    border-radius: 5px;
    outline: none;
  }
  
  .input-group input::placeholder {
    color: #f12289;
  }
  
  .forgot-password {
    color: #f12289;
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .checkbox-group input {
    margin-right: 10px;
  }
  
  .checkbox-group label {
    font-size: 14px;
  }
  
  .login-button {
    background-color: #f12289;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #A4126D;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .login-box {
      padding: 15px;
      max-width: 320px;
    }
  
    .login-button {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .login-container {
      padding: 20px;
    }
  
    .logo {
      height: 50px;
    }
  
    .input-group input {
      padding: 8px;
    }
  
    .login-button {
      font-size: 12px;
      padding: 8px;
    }
  }
  

  
  /* Admin Container */
  .admin-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    overflow-y: scroll;
  }
  
  /* Sidebar */
  .sidebar {
    background-color: #FF69B4;
    width: 200px;
    padding: 20px;
    color: #fff;
    height: 100vh;
  }
  
  .sidebar h2 {
    margin: 0 0 20px;
    font-size: 22px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 15px;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar ul li a:hover {
    text-decoration: underline;
  }
  
  /* Content Section */
  .content {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-bar {
    padding: 10px;
    width: 200px;
    margin-right: 10px;
  }
  
  .search-button, .logout-button {
    background-color: #FF69B4;
    color: white;
    border: none;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .search-button:hover, .logout-button:hover {
    background-color: #f12289;
  }
  
  /* Add Category Form */
  .add-category-form {
    background-color: #fce3ef;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
    margin-top: 40px;
  }
  
  .add-category-form h3 {
    color:#f12289;
    margin-bottom: 20px;
  }
  
  .add-category-form form {
    display: flex;
    flex-direction: column;
  }
  
  .add-category-form label {
    margin-bottom: 5px;
    color: #f12289;
  }
  
  .add-category-form input, .add-category-form select {
    padding: 10px;
    border: 1px solid #f12289;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .photo-upload {
    border: 1px solid #f12289;
    padding: 10px;
  }
  
  .add-button {
    background-color: #f12289;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .add-button:hover {
    background-color: #fd007f;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
   
  
    .sidebar {
      width: 100%;
      height: auto;
      text-align: center;
    }
    .admin-container {
      display: flex;
      height: 100%;
      justify-content: center;
      overflow-y: scroll;
    }
  
    .content {
      padding: 10px;
    }
  
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .search-bar {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .search-button, .logout-button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px;
    }
  
    .add-category-form {
      padding: 15px;
      height: 100%;
    }
    .container1 {
      width: 100%;
      margin: 20px auto;
    }
    select {
      padding: 10px;
      font-size: 16px;
      width: 100% !important;
    }
  }
  

  .sub-category-form {
    width: 100%;
    max-width:800px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
  }
  
  .sub-category-form h2 {
    text-align: center;
    color: #f12289;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #f12289;
    border-radius: 5px;
    outline: none;
  }
  
  .form-group input::placeholder {
    color: #f12289;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #f12289;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #e75480;
  }
  


  
  .container {
    width: 80%;
    margin: 20px auto;
  }
  .container1 {
    width: 100%;
    margin: 20px auto;
  }
  
  h1 {
    text-align: center;
    color: #ff69b4;
  }
  
  .category-selector {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  select {
    padding: 10px;
    font-size: 16px;
    width: 600px;
  }
  
  .search-btn, .add-btn {
    background-color: #ff69b4;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  th {
    background-color:#f12289;
    color: white;
  }
  
 
  
  .img-btn, .edit-btn, .delete-btn {
    background-color: #ff69b4;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .img-btn:hover, .edit-btn:hover, .delete-btn:hover {
    background-color: #ff1493;
  }
  ::placeholder{
    opacity: 0.8;
  }
  